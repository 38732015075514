import { graphql } from 'gatsby';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../../components/Layout/Layout';
import styles from './register-submitted.module.scss';

export const merchantOnboardingSubmittedPageQuery = graphql`
  query MerchantOnboardingSubmittedPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const MerchantOnboardingSubmittedPage: React.FC = () => {
  const { t, originalPath, i18n } = useI18next();

  return (
    <Layout
      t={t}
      originalPath={originalPath}
      i18n={i18n}
      SEOProps={{
        meta: [
          { name: 'robots', content: 'noindex, follow' },
          { name: 'googlebot', content: 'noindex, follow' },
        ],
      }}
      wrapperClassName={styles.mainWrapper}
    >
      <div className={styles.main}>
        <h1
          dangerouslySetInnerHTML={{
            __html: t('merchant-onboarding-submitted-title'),
          }}
        />
        <p>{t('merchant-onboarding-submitted-description')}</p>
        <Link to="/merchant" type="button">
          {t('back')}
        </Link>
      </div>
    </Layout>
  );
};

export default MerchantOnboardingSubmittedPage;
